<template>
  <div class="contact_us">
    <div class="con_nav">
      <nav-tab :nav-data="navData" />
    </div>
    <div class="con_center">
      <AboutUsItem />
    </div>
    <div class="con_bottom">
      <layout-bottom />
    </div>
  </div>
</template>

<script>
import NavTab from '@/components/NavTab'
import AboutUsItem from '@/components/aboutUs/aboutUsItem'
import LayoutBottom from '@/components/LayoutBottom'
export default {
  components: {
    NavTab,
    AboutUsItem,
    LayoutBottom
  },
  data() {
    return {
      navData: {
        index: 2,
        list: [
          {
            name: 'HOME',
            link: '/'
          },
          {
            name: 'SMART CREDIT SCORE',
            link: '/loan'
          },
          {
            name: 'ABOUT US',
            link: '/about_us'
          },
          {
            name: 'HOW IT WORKS',
            link: '/how_it_works'
          },
          {
            name: 'FAQ',
            link: '/faq'
          },
          {
            name: 'CONTACT US',
            link: '/contact_us'
          }]
      }
    }
  }

}
</script>

<style lang="scss" scoped>
@media only screen and (min-width: 768px){
  .con_center{
    padding-top: 88px;
  }
}
@media only screen and (max-width: 767px){}

</style>
